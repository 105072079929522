import { useCallback, useEffect, useState } from 'react';
import useDebounceCallback from '../useDebounceCallback';
import { useTutorial } from './useTutorial';
import useLocalStorageState from 'use-local-storage-state';
import { generateTutorialIndexPath } from '../../lib/helpers';
import { ACTIONS, EVENTS, STATUS } from 'react-joyride';

const DEFAULT_STATE = {
  tutorials: [],
  tutorialsIndex: 0,
  tutorialsRun: false,
  updateStep: null,
  errors: null,
};

const DEFAULT_PARAMS = {};
const DEFAULT_OPTIONS = {};

const getElementBySelector = async (selector) => {
  let intervalId;
  let timeoutId;

  return Promise.race([
    new Promise((resolve) => {
      timeoutId = setTimeout(() => {
        resolve();
      }, 5000);
    }),
    new Promise((resolve) => {
      const checkElement = () => {
        const element = document.querySelector(selector);
        if (element) {
          resolve(element);
        }
      };
      checkElement();
      intervalId = setInterval(checkElement, 200);
    }),
  ]).finally(() => {
    clearInterval(intervalId);
    clearTimeout(timeoutId);
  });
};

export const usePageTutorial = (
  pageId,
  params = DEFAULT_PARAMS,
  options = DEFAULT_OPTIONS,
) => {
  const { debounce = 0 } = options;
  const { tutorials: tutorialData, errors: tutorialDataErrors } = useTutorial(
    params,
    options,
  );

  const [tutorialsStorage, setTutorialsStorage] =
    useLocalStorageState('cms.tutorials');

  const [{ tutorials, tutorialsIndex, tutorialsRun, errors }, setResponseInfo] =
    useState(DEFAULT_STATE);

  useEffect(() => {
    if (tutorialDataErrors) {
      setResponseInfo({ ...DEFAULT_STATE, errors: tutorialDataErrors });
    }
  }, [tutorialDataErrors]);

  const reload = useCallback(() => {
    const filteredData = tutorialData?.filter((item) => item.page === pageId);

    if (
      JSON.parse(process.env.REACT_APP_ENABLE_TUTORIAL) &&
      tutorialData.length > 0 &&
      !tutorialsStorage
    ) {
      setTutorialsStorage({ run: true });
    }

    if (
      options?.pause ||
      !tutorialData?.length ||
      !filteredData?.length ||
      !tutorialsStorage
    ) {
      setResponseInfo({ ...DEFAULT_STATE, tutorialsRun: false });
      return;
    }

    const getTutorialIndexFromLocal =
      tutorialsStorage?.['path']?.[pageId]?.['index'];

    // Case: No page path generate new path for all
    if (getTutorialIndexFromLocal === undefined) {
      const currentNewPath = generateTutorialIndexPath(tutorialData);
      setTutorialsStorage({ run: true, path: currentNewPath });
    }

    const stepIndex = getTutorialIndexFromLocal || 0;
    const target = filteredData[stepIndex]?.target || 'body';

    setResponseInfo({
      ...DEFAULT_STATE,
      tutorials: filteredData,
      tutorialsRun: false,
      tutorialsIndex: stepIndex,
    });

    getElementBySelector(target).then((element) =>
      setResponseInfo((prevState) => ({
        ...prevState,
        tutorialsRun: !!element,
      })),
    );
  }, [
    options?.pause,
    pageId,
    setTutorialsStorage,
    tutorialData,
    tutorialsStorage,
  ]);

  /**
   * Tutorial callback event handler
   * @param {object} actionData data from joyride
   */
  const updateStep = useCallback(
    (actionData) => {
      const { action, index, status, type } = actionData;
      const currentTutorialPage = tutorials?.[index]?.page;

      // Case: back from navigation to page with other tutorial
      if (tutorialsStorage?.path?.[currentTutorialPage]?.index !== index) {
        return false;
      }

      const nextIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      let currentIndex = nextIndex >= 0 ? nextIndex : 0;

      // Case: first element is not found and not to freeze on that part
      if (
        ([EVENTS.TARGET_NOT_FOUND].includes('error:target_not_found') &&
          type === 'tour:start') ||
        ([EVENTS.TARGET_NOT_FOUND].includes(type) && index === 0)
      ) {
        currentIndex = 1;
      }

      if (
        [EVENTS.TARGET_NOT_FOUND].includes(type) ||
        ([EVENTS.STEP_AFTER].includes(type) && type === 'step:after')
      ) {
        setTutorialsStorage((prevState) => {
          if (prevState?.path?.[currentTutorialPage]) {
            prevState.path[currentTutorialPage].index = currentIndex;
          } else if (prevState?.path) {
            prevState.path[currentTutorialPage] = { currentIndex };
          }
          return prevState;
        });
      }

      if ([STATUS.SKIPPED].includes(status)) {
        setTutorialsStorage((prevState) => {
          return {
            ...prevState,
            run: false,
          };
        });
      }
    },
    [setTutorialsStorage, tutorials, tutorialsStorage],
  );

  const debouncedReload = useDebounceCallback(reload, debounce);
  useEffect(debouncedReload, [debouncedReload]);

  const result = [
    tutorials,
    tutorialsIndex,
    tutorialsRun,
    updateStep,
    errors,
    debouncedReload,
  ];

  Object.assign(result, {
    tutorials,
    tutorialsIndex,
    tutorialsRun,
    updateStep,
    errors,
    reload: debouncedReload,
  });

  return result;
};
