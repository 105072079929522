import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// :: Components
import Button from '../../Button/Button';
import Tooltip from '../../Tooltip/Tooltip';

// :: Images
import {
  CloseIcon,
  CaretLeftIcon,
  CaretRightIcon,
} from '../../../images/shapes';

// :: Lib
import { getTestProps } from '../../../lib/helpers';

// :: Hooks
import useSpace from '../../../hooks/useSpace';

// Styles
import './tutorial-tooltip.css';

const TutorialTooltip = (props) => {
  const {
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
    skipProps,
    size,
    testId,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { buildUrlWithSpace } = useSpace();

  const handleNavigate = useCallback(
    (path) => {
      navigate(buildUrlWithSpace(path === 'dashboard' ? '' : path));
    },
    [navigate, buildUrlWithSpace],
  );

  return (
    <div
      className={twMerge(
        'bg-white dark:bg-slate-700 p-4',
        'rounded-lg relative min-w-[400px]',
      )}
      {...getTestProps(testId || props?.step?.testId, `container-${index}`)}
      {...tooltipProps}
    >
      {!props?.step?.hideCloseButton && (
        <Button
          buttonColor="borderless"
          id="close"
          additionalClasses="absolute right-0 top-5"
          {...getTestProps(
            testId || props?.step?.testId,
            `close-${index}`,
            'testId',
          )}
          {...closeProps}
        >
          <CloseIcon
            className={twMerge(
              'w-2 h-2 sm:w-3 sm:h-3 text-indigo-950',
              'dark:text-white hover:text-gray-300',
            )}
            aria-hidden="true"
          />
        </Button>
      )}

      {step?.title && (
        <div
          className="text-black font-bold text-xl dark:text-white"
          {...getTestProps(testId || props?.step?.testId, `title-${index}`)}
        >
          {step.title}
        </div>
      )}

      <div
        dangerouslySetInnerHTML={{ __html: step?.content || '' }}
        className="text-black dark:text-white py-4 tutorial-content"
        {...getTestProps(testId || props?.step?.testId, `content-${index}`)}
      />

      <div className="flex flex-row">
        {skipProps && props?.step?.showSkipButton && (
          <Button
            id="skip"
            buttonColor="borderless"
            buttonSize="xs"
            noPaddings
            {...skipProps}
            onClick={(e) => skipProps?.onClick(e)}
            {...getTestProps(
              testId || props?.step?.testId,
              `skip-${index}`,
              'testId',
            )}
          >
            {t('Tutorials.Skip')}
          </Button>
        )}

        <div className="ml-auto flex flex-row">
          {index === 0 && props?.step?.data?.previous && (
            <Tooltip
              tooltip={`Go to ${props?.step?.data?.previous}`}
              tooltipPlacement={'topCenter'}
              phoneTooltipPlacement={'topCenter'}
            >
              <Button
                buttonSize="xs"
                iconImage={<CaretLeftIcon className="h-3 w-3" />}
                additionalClasses={'mx-2'}
                onClick={() => handleNavigate(props?.step?.data?.previous)}
                {...getTestProps(
                  testId || props?.step?.testId,
                  `navigate-previous`,
                  'testId',
                )}
              />
            </Tooltip>
          )}

          {index > 0 && (
            <Button
              id="back"
              buttonSize="xs"
              additionalClasses="mx-2"
              {...backProps}
              {...getTestProps(
                testId || props?.step?.testId,
                `back-${index}`,
                'testId',
              )}
            >
              {t('Tutorials.Back')}
            </Button>
          )}

          {continuous && (
            <Button
              id="next"
              buttonSize="xs"
              {...primaryProps}
              {...getTestProps(
                testId || props?.step?.testId,
                `next-${index}`,
                'testId',
              )}
            >
              <span
                {...getTestProps(
                  testId || props?.step?.testId,
                  `next-text-${index}`,
                )}
              >
                {props?.isLastStep ? t('Tutorials.Last') : t('Tutorials.Next')}
              </span>
              {props?.step?.showProgress && (
                <span
                  className="ml-2"
                  {...getTestProps(
                    testId || props?.step?.testId,
                    `progress-${index}`,
                  )}
                >
                  {index + 1} / {size}
                </span>
              )}
            </Button>
          )}

          {props?.isLastStep && props?.step?.data?.next && (
            <Tooltip
              tooltip={`Go to ${props?.step?.data?.next}`}
              tooltipPlacement={'topCenter'}
              phoneTooltipPlacement={'topCenter'}
            >
              <Button
                buttonSize="xs"
                iconImage={<CaretRightIcon className="h-3 w-3" />}
                additionalClasses={'mx-2'}
                {...getTestProps(
                  testId || props?.step?.testId,
                  `navigate-next`,
                  'testId',
                )}
                onClick={() => handleNavigate(props?.step?.data?.next)}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

TutorialTooltip.propTypes = {
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

TutorialTooltip.defaultProps = {
  testId: '',
  index: 0,
};

export default TutorialTooltip;
