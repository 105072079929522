import { useCallback, useEffect, useState } from 'react';
import useDebounceCallback from '../useDebounceCallback';
import { useRawTutorial } from '.';
import { useTranslation } from 'react-i18next';

const DEFAULT_STATE = {
  tutorials: [],
  errors: null,
};

const DEFAULT_PARAMS = {};
const DEFAULT_OPTIONS = {};

export const useTutorial = (
  params = DEFAULT_PARAMS,
  options = DEFAULT_OPTIONS,
) => {
  const { t, i18n } = useTranslation();
  const { debounce = 0 } = options;
  const { data: tutorialData, errors: tutorialDataErrors } = useRawTutorial(
    params,
    options,
  );

  const [{ tutorials, errors }, setResponseInfo] = useState(DEFAULT_STATE);

  const reload = useCallback(() => {
    if (tutorialData?.length) {
      const isLangPl = i18n.language === 'pl';
      const validateData = tutorialData
        .sort((a, b) => a?.order - b?.order)
        .map((item, index) => {
          return {
            placement: item.placement,
            title: isLangPl ? item.title_pl : item.title,
            content: isLangPl ? item?.content_pl : item?.content,
            target: item.target,
            data: {
              next: item.next,
              previous: item.previous,
            },
            disableBeacon: index === 0 ? true : item?.disableBeacon || false,
            page: item.page,
            locale: { open: t('Tutorials.OpenTutorial') }, // Modal tooltip hover description for beacon
            testId: item.testId,
          };
        });

      if (validateData.length > 0) {
        setResponseInfo({
          tutorials: validateData,
          errors: null,
        });
      }
    } else if (tutorialDataErrors) {
      setResponseInfo({
        ...DEFAULT_STATE,
        errors: tutorialDataErrors,
      });
    }
  }, [i18n.language, t, tutorialData, tutorialDataErrors]);

  const debouncedReload = useDebounceCallback(reload, debounce);
  useEffect(debouncedReload, [debouncedReload]);

  const result = [tutorials, errors, debouncedReload];
  Object.assign(result, {
    tutorials,
    errors,
    reload: debouncedReload,
  });
  return result;
};
