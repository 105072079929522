import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { TailSpin } from 'react-loader-spinner';
import { twMerge } from 'tailwind-merge';
import Button from '../../../components/Button/Button';
import Tooltip from '../../../components/Tooltip/Tooltip';
import { useConstraints } from '../../../hooks/api';
import useApiErrorsToast from '../../../hooks/api/useApiErrorsToast';
import useToken from '../../../hooks/useToken';
import { getFormatedSize, getTestProps } from '../../../lib/helpers';
import {
  checkoutPlanProperty,
  updateSpacePlan,
} from '../../../lib/flotiq-client';
import {
  checkResponseStatus,
  ResponseError,
} from '../../../lib/flotiq-client/response-errors';
import {
  ArrowRightThinIcon,
  ArrowUpThinIcon,
  InformationCircleIcon,
  WarningTriangleIcon,
} from '../../../images/shapes';
import CheckCircle from '../../../components/CheckCircle/CheckCircle';
import moment from 'moment/moment';

const getLimitPercent = (value, limit) => {
  if (limit === -1 || (!value && !limit)) return 0;
  if (!limit && value) return 100;
  return (value / limit) * 100;
};

const Detail = ({ detail, isTooltipCenter, testId }) => {
  const { t } = useTranslation();

  if (!detail.percent || detail.percent < 90)
    return (
      <div>
        <span className="font-bold text-lg">{detail.value}</span>
        {detail.value != null && ' / '}
        {detail.limit === -1 ? t('Global.Unlimited') : detail.limit}
      </div>
    );

  const tooltipKey =
    detail.percent < 100
      ? 'Spaces.LimitAlmostExceeded'
      : 'Spaces.LimitExceeded';

  return (
    <div>
      <span className="font-bold text-lg text-red">{detail.value}</span>
      {detail.value != null && ' / '}
      {detail.limit === -1 ? t('Global.Unlimited') : detail.limit}
      <Tooltip
        tooltip={t(tooltipKey, { label: detail.label })}
        tooltipPlacement={isTooltipCenter ? 'topCenter' : 'topRight'}
        phoneTooltipPlacement={isTooltipCenter ? 'topCenter' : 'topRight'}
        additionalClasses="text-red font-bold w-fit inline ml-2"
        additionalTooltipClasses="font-normal bg-red dark:bg-red after:bg-red dark:after:bg-red"
        {...getTestProps(testId, 'exceeded', 'testId')}
      >
        <WarningTriangleIcon className="inline-block w-4 mb-px align-text-bottom" />
      </Tooltip>
    </div>
  );
};

const SpaceLimits = ({
  id,
  planLimits,
  paymentDueDate,
  paymentStatus,
  isAdmin,
  isBuyExtraVisible,
  testId,
}) => {
  const { t } = useTranslation();
  const jwt = useToken();
  const [loadingBuyExtra, setLoadingBuyExtra] = useState('');
  const [isSpaceSaving, setIsSpaceSaving] = useState(false);

  const constraintsParams = useMemo(
    () => ({
      space: id,
    }),
    [id],
  );

  const constraintsOptions = useMemo(
    () => ({
      pause: !isAdmin,
    }),
    [isAdmin],
  );

  const { entity: ctoCount, errors: ctoErrors } = useConstraints(
    'cto-count',
    constraintsParams,
    constraintsOptions,
  );

  const { entity: ctdCount, errors: ctdErrors } = useConstraints(
    'ctd-count',
    constraintsParams,
    constraintsOptions,
  );
  const { entity: mediaCount, errors: mediaErrors } = useConstraints(
    'media-sum-size',
    constraintsParams,
    constraintsOptions,
  );
  const { entity: apiKeysCount, errors: apiKeysErrors } = useConstraints(
    'scoped-keys-count',
    constraintsParams,
    constraintsOptions,
  );
  const { entity: teamMembersCount, errors: teamMembersErrors } =
    useConstraints('team-members-count', constraintsParams, constraintsOptions);

  const { entity: webhooksCount, errors: webhooksErrors } = useConstraints(
    'webhooks-count',
    constraintsParams,
    constraintsOptions,
  );

  const { entity: apiCallsCount, errors: apiCallsErrors } = useConstraints(
    'api-calls-count',
    constraintsParams,
    constraintsOptions,
  );

  const { entity: officialPluginsCount, errors: officialPluginsErrors } =
    useConstraints(
      'official-plugins-count',
      constraintsParams,
      constraintsOptions,
    );

  const { entity: customPluginsCount, errors: customPluginsErrors } =
    useConstraints(
      'custom-plugins-count',
      constraintsParams,
      constraintsOptions,
    );

  const { entity: hostedWebhookCount, errors: reloadHostedWebhookErrors } =
    useConstraints('hosted-webhooks-count');

  useApiErrorsToast(ctoErrors);
  useApiErrorsToast(ctdErrors);
  useApiErrorsToast(mediaErrors);
  useApiErrorsToast(apiKeysErrors);
  useApiErrorsToast(teamMembersErrors);
  useApiErrorsToast(webhooksErrors);
  useApiErrorsToast(apiCallsErrors);
  useApiErrorsToast(officialPluginsErrors);
  useApiErrorsToast(customPluginsErrors);
  useApiErrorsToast(reloadHostedWebhookErrors);

  const allowedHosts = useMemo(() => {
    const hosts = planLimits?.allowed_hosts_for_custom_plugins?.split(',');
    if (!hosts || !hosts.length) return '-';
    if (hosts.includes('*')) return t('Global.Unlimited');
    return hosts;
  }, [planLimits?.allowed_hosts_for_custom_plugins, t]);

  const resourcesSpaceDetails = useMemo(
    () => [
      {
        key: 'space_cto',
        label: t('Global.ContentTypeObjects'),
        limit: getFormatedSize(
          planLimits?.cto_limit,
          'compact-number',
          t('Global.Unlimited'),
        ),
        property: 'cto_limit',
        value: ctoCount?.data,
        percent: getLimitPercent(ctoCount?.data, planLimits?.cto_limit),
        tooltip: t('Limits.Description.ContentTypeObjects'),
      },
      {
        key: 'space_ctd',
        label: t('Global.ContentTypeDefinitions'),
        limit: getFormatedSize(
          planLimits?.ctd_limit,
          ' ',
          t('Global.Unlimited'),
        ),
        property: 'ctd_limit',
        value: ctdCount?.data,
        percent: getLimitPercent(ctdCount?.data, planLimits?.ctd_limit),
        tooltip: t('Limits.Description.ContentTypeDefinitions'),
      },
      {
        key: 'space_media',
        label: t('Global.FileQuota'),
        limit: getFormatedSize(
          planLimits?.file_quota,
          'MB',
          t('Global.Unlimited'),
        ),
        property: 'file_quota',
        value: mediaCount?.data !== undefined && `${mediaCount?.data} MB`,
        percent: getLimitPercent(mediaCount?.data, planLimits?.file_quota),
        tooltip: t('Limits.Description.FileQuota'),
      },
      {
        key: 'space_maxMediaSize',
        label: t('Global.MaxAssetSize'),
        limit: getFormatedSize(
          planLimits?.max_asset_size,
          'MB',
          t('Global.Unlimited'),
        ),
        tooltip: t('Limits.Description.MaxAssetSize'),
      },
      {
        key: 'space_apiKeys',
        label: t('Global.ScopedApiKeysCount'),
        limit: getFormatedSize(
          planLimits?.scoped_keys_limit,
          '',
          t('Global.Unlimited'),
        ),
        value: apiKeysCount?.data,
        percent: getLimitPercent(
          apiKeysCount?.data,
          planLimits?.scoped_keys_limit,
        ),
        tooltip: t('Limits.Description.ScopedApiKeysCount'),
      },
      {
        key: 'space_scopedApiKeysDocs',
        label: t('Global.ScopedApiKeysDocs'),
        limit: (
          <CheckCircle
            checked={!!planLimits?.scoped_keys_docs}
            additionalClasses="w-5"
          />
        ),
        tooltip: t('Limits.Description.ScopedApiKeysDocs'),
      },
      {
        key: 'space_team',
        label: t('Global.TeamMembers'),
        limit: getFormatedSize(
          planLimits?.team_members_limit,
          '',
          t('Global.Unlimited'),
        ),
        property: 'team_members_limit',
        value: teamMembersCount?.data,
        percent: getLimitPercent(
          teamMembersCount?.data,
          planLimits?.team_members_limit,
        ),
        tooltip: t('Limits.Description.TeamMembers'),
      },
      {
        key: 'space_webhooks',
        label: t('Global.Webhooks'),
        limit: getFormatedSize(
          planLimits?.webhooks_limit,
          '',
          t('Global.Unlimited'),
        ),
        value: webhooksCount?.data,
        percent: getLimitPercent(
          webhooksCount?.data,
          planLimits?.webhooks_limit,
        ),
        tooltip: t('Limits.Description.Webhooks'),
      },
      {
        key: 'space_hostedWebhooks',
        label: t('Global.HostedWebhook'),
        limit: getFormatedSize(
          planLimits?.hosted_webhooks_limit,
          '',
          t('Global.Unlimited'),
        ),
        value: hostedWebhookCount?.data,
        percent: getLimitPercent(
          hostedWebhookCount?.data,
          planLimits?.hosted_webhooks_limit,
        ),
        tooltip: t('Limits.Description.HostedWebhook'),
      },
      {
        key: 'space_apiCalls',
        label: t('Global.APICalls'),
        limit: getFormatedSize(
          planLimits?.api_calls_limit,
          'compact-number',
          t('Global.Unlimited'),
        ),
        property: 'api_calls_limit',
        value: apiCallsCount?.data,
        tooltip: t('Limits.Description.APICalls'),
      },
      {
        key: 'space_officialPlugins',
        label: t('Global.OfficialPlugins'),
        limit: getFormatedSize(
          planLimits?.official_plugins_limit,
          '',
          t('Global.Unlimited'),
        ),
        value: officialPluginsCount?.data,
        percent: getLimitPercent(
          officialPluginsCount?.data,
          planLimits?.official_plugins_limit,
        ),
        tooltip: t('Limits.Description.OfficialPlugins'),
      },
      {
        key: 'space_customPlugins',
        label: t('Global.CustomPlugins'),
        limit: getFormatedSize(
          planLimits?.custom_plugins_limit,
          '',
          t('Global.Unlimited'),
        ),
        value: customPluginsCount?.data,
        percent: getLimitPercent(
          customPluginsCount?.data,
          planLimits?.custom_plugins_limit,
        ),
        tooltip: t('Limits.Description.CustomPlugins'),
      },
      {
        key: 'space_allowedHosts',
        label: t('Global.AllowedHosts'),
        limit: allowedHosts,
        tooltip: t('Limits.Description.AllowedHosts'),
      },
      {
        key: 'space_userRolesEnabled',
        label: t('Global.UserRoles'),
        limit: (
          <CheckCircle
            checked={!!planLimits?.user_roles_enabled}
            additionalClasses="w-5"
          />
        ),
        tooltip: t('Limits.Description.UserRoles'),
      },
    ],
    [
      t,
      ctoCount?.data,
      planLimits,
      ctdCount?.data,
      mediaCount?.data,
      apiKeysCount?.data,
      teamMembersCount?.data,
      webhooksCount?.data,
      hostedWebhookCount?.data,
      apiCallsCount?.data,
      officialPluginsCount?.data,
      customPluginsCount?.data,
      allowedHosts,
    ],
  );

  const handleBuyExtra = useCallback(
    async (propertyName) => {
      try {
        // Button on loading
        setLoadingBuyExtra(propertyName);

        const { body, status } = await checkoutPlanProperty(jwt, null, {
          propertyName: propertyName,
          spaceId: id,
          uri: `${
            process.env.PUBLIC_URL || window.origin
          }/organization?spaceId=${id}&planId=${planLimits?.id}&planName=${
            planLimits?.name
          }`,
        });

        // Check responcse status
        checkResponseStatus(body, status);

        // Remove loading for extra
        setLoadingBuyExtra('');

        // Redirect to body url
        window.location.assign(body.url);
      } catch (e) {
        toast.error(t('Form.CommunicationErrorMessage'));
        setLoadingBuyExtra('');
      }
    },
    [jwt, id, planLimits?.id, planLimits?.name, t],
  );

  const ButtonBuyExtra = useCallback(
    ({ detail }) => {
      // Available extend options
      const availableToExtend = ['space_team'];

      // Remove button if not include extend option
      if (!availableToExtend.includes(detail.key) || !detail?.property) {
        return;
      }

      return (
        <Button
          buttonSize={'sm'}
          as={'button'}
          buttonColor={'borderless'}
          onClick={() => handleBuyExtra(detail.property)}
          iconImage={<ArrowUpThinIcon className="w-3 h-3" />}
          iconPosition={'end'}
          disabled={detail.limit === -1}
          additionalClasses={'ml-auto w-fit'}
          additionalChildrenClasses={'flex flex-row-reverse'}
          {...getTestProps(testId, `buy-extra-${detail.key}`)}
        >
          {t('AccountSettings.BuyExtra')}
          <TailSpin
            ariaLabel="tail-spin-loading"
            color="#0083FC"
            height="15"
            radius="1"
            width="20"
            wrapperClass="mr-2"
            visible={loadingBuyExtra === detail.property}
          />
        </Button>
      );
    },
    [testId, t, loadingBuyExtra, handleBuyExtra],
  );

  const handleUpgradeSpace = useCallback(async () => {
    setIsSpaceSaving(true);

    try {
      const { body, status } = await updateSpacePlan(jwt, undefined, {
        id: id,
        planId: planLimits.id,
        period: 'month',
        uri: window.location.toString(),
      });
      checkResponseStatus(body, status);

      setIsSpaceSaving(false);

      if (body.status === 'payment_required') {
        window.location.assign(body.url);
      }
    } catch (error) {
      setIsSpaceSaving(false);

      if (!(error instanceof ResponseError)) {
        toast.error(t('Form.CommunicationErrorMessage'));
        return;
      }

      toast.error(t('Spaces.Form.CouldntUpdate'));
    }
  }, [jwt, id, planLimits.id, t]);

  return (
    <>
      <div className="flex flex-wrap lg:flex-nowrap items-center justify-between mb-3 text-base lg:text-lg">
        <div className="pr-5">
          {t('Spaces.YourSpacePlan')}
          <span
            className="font-bold ml-1 xs:text-lg lg:text-xl"
            {...getTestProps(testId, 'plan-name')}
          >
            {planLimits?.visible_name}
          </span>
        </div>
        {paymentStatus === 'trial' ? (
          <div className="flex items-end">
            <span>{t('Spaces.FreeTrialEnds')}</span>
            <span className="font-bold xs:text-lg lg:text-xl pl-2 mb-[-2px]">
              {moment(paymentDueDate).format('DD.MM.YYYY')}
            </span>
            <Button
              buttonSize={'sm'}
              as={'button'}
              buttonColor={'borderless'}
              onClick={handleUpgradeSpace}
              iconImage={<ArrowRightThinIcon className="w-3 h-3" />}
              iconPosition={'end'}
              additionalClasses={'ml-auto w-fit px-2'}
              additionalChildrenClasses={'flex flex-row-reverse'}
              {...getTestProps(testId, `buy-now`)}
            >
              {t('AccountSettings.BuyNow')}
              <TailSpin
                ariaLabel="tail-spin-loading"
                color="#0083FC"
                height="15"
                radius="1"
                width="20"
                wrapperClass="mr-2"
                visible={isSpaceSaving}
              />
            </Button>
          </div>
        ) : (
          ''
        )}
      </div>

      {resourcesSpaceDetails.map((detail) => (
        <div
          key={detail.key}
          className={twMerge(
            'py-1 md:py-2 grid gap-2 text-base items-center',
            'border-b border-slate-200 dark:border-slate-800 last:border-none',
            isBuyExtraVisible ? 'grid-cols-3' : 'grid-cols-2',
          )}
          {...getTestProps(testId, detail.key)}
        >
          <div>
            {detail.label}
            {detail.tooltip && (
              <Tooltip
                tooltip={detail.tooltip}
                phoneTooltipPlacement="topLeft"
                additionalClasses="inline-block align-middle px-1 md:px-2"
                {...getTestProps(testId, detail.key, 'testId')}
              >
                <InformationCircleIcon className="text-blue min-w-4 w-4" />
              </Tooltip>
            )}
          </div>
          <Detail
            detail={detail}
            isTooltipCenter={isBuyExtraVisible}
            {...getTestProps(testId, detail.key, 'testId')}
          />
          {isBuyExtraVisible && planLimits?.price > 0 && (
            <ButtonBuyExtra detail={detail} />
          )}
        </div>
      ))}
    </>
  );
};

export default SpaceLimits;

SpaceLimits.propTypes = {
  /**
   * Space details id
   */
  id: PropTypes.string,
  /**
   * Space details plan limits details
   */
  planLimits: PropTypes.any,
  /**
   * Space details payment due date
   */
  paymentDueDate: PropTypes.string,
  /**
   * Space details payment status
   */
  paymentStatus: PropTypes.string,
  /**
   * Space details if is admin
   */
  isAdmin: PropTypes.bool,
  /**
   * If buy extra button is visible
   */
  isBuyExtraVisible: PropTypes.bool,
  /**
   * Test id for page
   */
  testId: PropTypes.string,
};

SpaceLimits.defaultProps = {
  id: '',
  planLimits: {},
  isAdmin: false,
  isBuyExtraVisible: true,
  testId: '',
};
